<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-4" v-for="(step, i) in conversation" :key="i">
      <data-card
        v-bind:card-id="step.name"
        v-bind:theme="'blue'"
        v-bind:name="step.name"
        v-bind:value="step.total">
        <message-icon/>
      </data-card>
    </div>
    <div class="cell small-12 medium-12">
      <data-chart
        ref="conversationSteps"
        v-bind:name="'app.invocations'"
        v-bind:chart-id="'conversationSteps'"
        v-bind:chart-data="{ categories: labels, series }"
        v-bind:type="'line'"
        v-on:change="chartRangeChanged"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import conversationSteps from '@/graphql/queries/conversations/BotStepConversastions.gql';
import executeQuery from '@/utils/gql-api';

export default {
  props: {
    filters: {
      required: true,
    },
  },
  components: {
    DataCard: () => import('@/components/DataCard.vue'),
    DataChart: () => import('@/components/DataChart.vue'),
  },
  data() {
    return {
      campaignId: null,
      conversation: [{ name: 'conversationIvr' }],
      labels: [],
      series: [],
      chartOptions: {},
    };
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
    }),
    hasData() {
      return this.conversation.length > 0;
    },
    queryConversations() {
      const start = moment(this.filters.startDate);
      const end = moment(this.filters.endDate).endOf('day');
      return {
        end,
        start,
        campaign: this.campaignId,
      };
    },
  },
  watch: {
    filters(newValue, oldVal) {
      if (!newValue.campaignId) {
        this.clearData();
        this.$toasted.global.error(this.$t('app.ivrConversations'));
      } else if (newValue && this.validRequest(newValue, oldVal)) {
        this.campaignId = newValue.campaignId;
        this.getConversationSteps();
      }
    },
  },
  mounted() {
    if (this.filters.campaignId != null) {
      this.campaignId = this.filters.campaignId;
      this.getConversationSteps();
    } else {
      this.$toasted.global.error(this.$t('app.ivrConversations'));
    }
  },
  methods: {
    async getConversationSteps() {
      this.$store.commit('dashboard/enableLoader', 'conversationIvr');
      const steps = await executeQuery(
        'conversationSteps',
        conversationSteps,
        this.queryConversations,
      );
      if (steps.length === 0) {
        this.clearData();
      } else {
        this.mapSteps(steps);
      }
    },
    mapSteps(steps) {
      this.createGraphOfInvocaciones(steps);
      this.conversation = steps.sort((a, b) => this.orderByName(a, b));
    },
    createGraphOfInvocaciones(map) {
      const { label, serie } = map.reduce((acc, step) => {
        acc.label.push(step.name);
        acc.serie.push(step.total);
        return acc;
      }, { label: [], serie: [] });

      this.labels = label;
      this.series = [{ data: serie, name: 'Steps' }];
    },
    orderByName(a, b) {
      return a.name.localeCompare(b.name);
    },
    chartRangeChanged() {
    },
    validRequest({ endDate, startDate, campaignId }, oldVal) {
      return (endDate && startDate && oldVal.endDate
        && oldVal.startDate && endDate !== oldVal.endDate
        && startDate !== oldVal.startDate)
        || (campaignId != null && campaignId !== oldVal.campaignId);
    },
    clearData() {
      this.conversation = [];
      this.labels = [];
      this.series = [];
    },
  },
};

</script>
<style scoped lang="scss">
.empty-rows {
  background-color: white;
}

.download {
  margin-bottom: 10px;
}
</style>
